import React, { useCallback, useEffect, useState } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// DESIGN
import {
    Button, Grid, CircularProgress, TextField, Typography, InputAdornment, IconButton, OutlinedInput, InputLabel, FormControl,
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';

import ForgotPassword from 'Components/Pages/ForgotPassword/ForgotPassword';

// STYLES
import { BoxWrapper, BoxContainer } from "Styles/Utils";
import { CircularProgressContainer, ButtonContainer, AutoCompleteContainer } from '../Pages.style';

// ICONS
// import { FormHelperText } from '@material-ui/core';
import { VisibilityOff as VisibilityOffIcon, Visibility as VisibilityIcon } from '@material-ui/icons';
import TransparentLogo from 'Assets/Images/transparent-logo.png'

// CONSTANTS
import { URL_DASHBOARD, URL_USER, API_URL } from "Helpers/Paths";
import ApiHandler from "Helpers/ApiHandler";

//FORMIK
import { Formik } from "formik";
import * as Yup from "yup";

// REDUX
// import authAction from 'Redux/Auth/Actions';
// const { login } = authAction;
import { loginUser, logOutUser } from "Redux/Auth/Actions";
import { store } from 'Redux/Store';

function Login(props) {
    const [showPassword, setShowPassword] = React.useState(false);
    const [showFrgtPwdDialog, setFrgtPwdDialog] = React.useState(false);
    const [loader, setLoader] = React.useState(false);
    const [isCompanyManagerLoggedIn, setIsCompanyManagerLoggedIn] = useState(false);
    // const [selectedCompanyByCompanyManager, setSelectedCompanyByCompanyManager] = useState(null);
    const [companiesListForCompanyManager, setCompaniesListForCompanyManager] = useState([]);
    const [loggedInUserResponse, setLoggedInUserResponse] = useState(null);

    const companyListFormRef = React.useRef();

    const checkIfAlreadyLoggedIn = useCallback(() => {
        const userData = localStorage.getItem("info") ? JSON.parse(localStorage.getItem("info")) : null;

        if (props.isLoggedIn) {
            if (userData && userData.isIntegratedWithMovegistics && parseInt(localStorage.getItem("user")) !== 1) {
                props.history.push(URL_USER);
            } else {
                props.history.push(URL_DASHBOARD)
            }
        }

        if (localStorage.getItem("IsCompanyManagerLoggedIn") && localStorage.getItem("groupCompanyId") && localStorage.getItem("groupCompanyId") === "null") {
            const api = new ApiHandler();
            api.get(API_URL.GET_ALL_COMPANIES_FOR_COMPANY_MANAGER).then(allCompanyListResponse => {
                setCompaniesListForCompanyManager(allCompanyListResponse.data);
                setIsCompanyManagerLoggedIn(true);
            })

            setLoader(false);
            return;
        }
    }, [props.history, props.isLoggedIn])

    const loginUser = (values) => {
        setLoader(true);

        try {
            const api = new ApiHandler();
            api.post(API_URL.LOG_IN, {
                data: {
                    deviceToken: "CMS",
                    deviceType: 0,
                    email: values.email,
                    password: values.password
                }
            }).then(response => {
                // const { login } = props;
                // login(response.data.data.accessToken);

                // props.loginUser(response.data);

                setLoggedInUserResponse(response.data);

                localStorage.setItem('authToken', response.data.accessToken);

                if ((!response.data.companyId || response.data.companyId === '') && (response.data.groupCompanyId !== "" || response.data.groupCompanyId === "") && response?.data?.role?.roleName === "Company Manager") {
                    localStorage.setItem("IsCompanyManagerLoggedIn", true);
                    localStorage.setItem("groupCompanyId", null);

                    const newAPIHandler = new ApiHandler();

                    newAPIHandler.get(API_URL.GET_ALL_COMPANIES_FOR_COMPANY_MANAGER).then(allCompanyListResponse => {
                        setCompaniesListForCompanyManager(allCompanyListResponse.data);
                        setIsCompanyManagerLoggedIn(true);
                    })

                    setLoader(false);
                    return;
                } else {
                    props.loginUser(response.data);
                }

                if (response.data.isIntegratedWithMovegistics && response.data.role.roleName !== "Super Admin") {
                    props.history.push("/users");
                } else {
                    props.history.push(URL_DASHBOARD);
                }

                setLoader(false);
            }).catch(error => {
                setLoader(false);
            })
        } catch (error) {
            setLoader(false);
        }
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleToggleFrgtPwdDialog = () => {
        setFrgtPwdDialog(!showFrgtPwdDialog);
    };

    const testSchema = Yup.object().shape({
        email: Yup.string()
            .email("Enter a valid email")
            .required(" "),
        password: Yup.string()
            // .min(8, "Password must contain at least 8 characters")
            .required(" "),
    });

    const groupCompanyIdSchema = Yup.object().shape({
        groupCompanyId: Yup.string().nullable().required(" "),
    });

    const initialValues = {
        email: "",
        password: ""
    };

    const selectCompanyForCompanyManager = async (values) => {
        const api = new ApiHandler();
        await api.put(`${API_URL.SAVE_SELECTED_COMPANY_FOR_COMPANY_MANAGER}/${values?.groupCompanyId?.id}`).then(response => {
            localStorage.setItem("groupCompanyId", values?.groupCompanyId?.id);
            localStorage.setItem("selectedCompanyGroupDetails", JSON.stringify(values?.groupCompanyId));

            let loggedInResponse = { ...loggedInUserResponse };
            if (response?.data?.isIntegratedWithMovegistics) {
                loggedInResponse.isIntegratedWithMovegistics = response?.data?.isIntegratedWithMovegistics;
            }

            if (response?.data?.isIntegratedWithMoverInventory) {
                loggedInResponse.isIntegratedWithMoverInventory = response?.data?.isIntegratedWithMoverInventory;
            }
            
            setLoggedInUserResponse(loggedInResponse);
            props.loginUser(loggedInResponse);
        })
    }

    const handleLogout = async () => {
        const api = new ApiHandler();
        await api.get(API_URL.LOGOUT).then(response => {
            store.dispatch(logOutUser());

            setLoggedInUserResponse(null);
            setCompaniesListForCompanyManager([]);
            setIsCompanyManagerLoggedIn(false);
        })
    }

    useEffect(() => {
        checkIfAlreadyLoggedIn();
    }, [checkIfAlreadyLoggedIn])

    return (
        <>
            {
                !isCompanyManagerLoggedIn ?
                    <BoxWrapper className="flex f-v-center f-h-center">
                        <div className="logo-wrapper">
                            <img src={TransparentLogo} alt="logo" />
                        </div>
                        <BoxContainer className="box">
                            <Typography variant="h4" className="form-title">
                                Login
                            </Typography>
                            {/* Formik example */}
                            <Formik
                                validationSchema={testSchema}
                                initialValues={initialValues}
                                onSubmit={(values, actions) => {
                                    actions.setSubmitting(false);
                                    loginUser(values);
                                }}
                            >
                                {({ handleChange, values, errors, touched, handleSubmit, isSubmitting, setFieldTouched }) => {
                                    return (
                                        <>
                                            <Grid container spacing={2}>
                                                <div className="form-container">
                                                    <TextField
                                                        className="form-item"
                                                        label="Email Id"
                                                        name="email"
                                                        margin="normal"
                                                        variant="outlined"
                                                        error={touched.email && Boolean(errors.email)}
                                                        onChange={handleChange("email")}
                                                        value={values.email}
                                                        fullWidth
                                                        required
                                                    />
                                                    <div style={{ marginTop: '5px' }}>{errors.email}</div>

                                                    <FormControl
                                                        variant="outlined"
                                                        className="form-item"
                                                        error={touched.password && Boolean(errors.password)}
                                                        required
                                                        fullWidth
                                                    >
                                                        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                                        <OutlinedInput
                                                            id="password"
                                                            label="Password"
                                                            onChange={handleChange("password")}
                                                            required
                                                            type={showPassword ? 'text' : 'password'}
                                                            endAdornment={
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        onClick={handleClickShowPassword}
                                                                        onMouseDown={handleMouseDownPassword}
                                                                    >
                                                                        {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }
                                                            labelWidth={70}
                                                        />
                                                        <div style={{ marginTop: '5px' }}>{errors.password}</div>
                                                    </FormControl>
                                                </div>

                                                <Grid item xs>
                                                    <ButtonContainer>
                                                        <Button className={"btn"} variant="contained" onClick={handleSubmit} color="primary">
                                                            {loader ?
                                                                <CircularProgressContainer>
                                                                    <CircularProgress color="white" size={30} />
                                                                </CircularProgressContainer>
                                                                : null}
                                                            <div>Login</div>
                                                        </Button>
                                                    </ButtonContainer>
                                                </Grid>
                                            </Grid>
                                        </>
                                    );
                                }}
                            </Formik>

                            <Typography
                                variant="subtitle1"
                                className="forgot-pwd"
                                onClick={handleToggleFrgtPwdDialog}
                            >
                                Forgot Password?
                            </Typography>
                        </BoxContainer>
                        <ForgotPassword
                            isOpen={showFrgtPwdDialog}
                            handleClose={handleToggleFrgtPwdDialog}
                        />
                    </BoxWrapper>
                    :
                    <BoxWrapper className="flex f-v-center f-h-center">
                        <div className="logo-wrapper">
                            <img src={TransparentLogo} alt="logo" />
                        </div>
                        <BoxContainer className="box">
                            <Formik
                                innerRef={companyListFormRef}
                                validationSchema={groupCompanyIdSchema}
                                initialValues={{ groupCompanyId: "" }}
                                onSubmit={(values, actions) => {
                                    actions.setSubmitting(false);
                                    selectCompanyForCompanyManager(values);
                                }}
                            >
                                {({ handleChange, handleBlur, values, errors, touched, handleSubmit, isSubmitting, setFieldTouched, setFieldValue }) => {
                                    return (
                                        <>
                                            <AutoCompleteContainer>
                                                <Autocomplete
                                                    id="groupCompanyId"
                                                    options={companiesListForCompanyManager}
                                                    value={values.groupCompanyId}
                                                    error={touched.groupCompanyId && Boolean(errors.groupCompanyId)}
                                                    getOptionSelected={(option, value) => value.companyName === option.companyName}
                                                    filterSelectedOptions
                                                    required
                                                    getOptionLabel={(option) => typeof option === 'string' ? option : option.companyName}
                                                    onChange={(e, valueTags) => {
                                                        e.preventDefault();
                                                        setFieldValue("groupCompanyId", valueTags);
                                                    }}
                                                    renderInput={(params) => <TextField {...params}
                                                        error={touched.groupCompanyId && Boolean(errors.groupCompanyId)}
                                                        label="Company"
                                                        placeholder="Company"
                                                        variant="outlined"
                                                        required />
                                                    }
                                                />
                                                {errors.company}
                                            </AutoCompleteContainer>

                                            <Grid item xs>
                                                <ButtonContainer>
                                                    <Button className={"btn cancel-button"} variant="contained" onClick={handleLogout} color="primary">
                                                        {loader ?
                                                            <CircularProgressContainer>
                                                                <CircularProgress color="white" size={30} />
                                                            </CircularProgressContainer>
                                                            : null}
                                                        <div>Cancel</div>
                                                    </Button>
                                                    <Button className={"select-company"} variant="contained" onClick={handleSubmit} color="primary">
                                                        {loader ?
                                                            <CircularProgressContainer>
                                                                <CircularProgress color="white" size={30} />
                                                            </CircularProgressContainer>
                                                            : null}
                                                        <div>Select</div>
                                                    </Button>
                                                </ButtonContainer>
                                            </Grid>
                                        </>
                                    );
                                }}
                            </Formik>
                        </BoxContainer>
                    </BoxWrapper>
            }
        </>
    )
}

const mapReduxStateToProps = (state) => ({
    isLoggedIn: state.Auth.isLoggedIn,
})

export default connect(mapReduxStateToProps, {
    loginUser
})(withRouter(Login));

