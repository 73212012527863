//WEBSITE URL OF PATH
export const URL_DASHBOARD              = "/dashboard";
export const URL_USER                   = "/users";
export const URL_CUSTOMER               = "/customers";
export const URL_COMPANY                = "/companies";
export const URL_COMPANY_GROUP          = "/company-group";
export const URL_LOGIN                  = "/login";
export const URL_RESET_PASSWORD         = "/reset-password";
export const URL_UNITS                  = "/units";
export const URL_WAREHOUSE_UNITS        = "/warehouse-units";
export const URL_SHIPMENTS              = "/shipments";
export const URL_WAREHOURSE             = "/warehouse";
export const URL_WAREHOURSE_LAYOUT      = "/warehouse_layout/";
export const URL_CHANGE_PASSWORD        = "/change-password";
export const URL_COMPANY_LABEL          = "/company-label";
export const URL_STATIC_CONTENT         = "/static-content";
export const URL_TAGS                   = "/tags";
export const URL_EDIT_PROFILE           = "/edit-profile";

export const API_URL = {

    //ADMIN 
    LOG_IN                 : "admin/login",
    CHANGE_PASSWORD        : "admin/change-password",
    FORGOT_PASSWORD        : "admin/forgot-password",
    RESET_PASSWORD         : "admin/reset-password",
    
    TOKEN_VALIDITY         : "admin/token-validity/",
    LOGOUT                 : "admin/logout",   
    EDIT_PROFILE           : "admin/profile/",
    GET_DASHBOARD          : "admin/dashboard",
    
    //COMPANY
    ADD_COMPANY                                 : "admin/companies",
    EDIT_COMPANY                                : "admin/companies/",
    DELETE_COMPANY                              : "admin/companies/",
    EDIT_COMPANY_IS_ACTIVE                      : "admin/companies/active-status/",
    GET_PAGINATED_COMPANIES                     : "admin/companies/list",
    GET_ALL_COMPANIES_FOR_COMPANY_MANAGER       : "admin/company-manager-companies/list",
    GET_ALL_COMPANIES_FOR_DROPDOWN              : "admin/all-company-group/list",
    SAVE_SELECTED_COMPANY_FOR_COMPANY_MANAGER   : "admin/save-default-group-company",


    //COMPANY GROUP
    ADD_COMPANY_GROUP                                : "admin/company-group",
    EDIT_COMPANY_GROUP                               : "admin/company-group",
    GET_PAGINATED_COMPANY_GROUP                      : "admin/company-group/list",
    GET_COMPANY_GROUP_USERS_BY_COMPANY_GROUP_ID      : "admin/fetch-company-group-user/",
    // DELETE_COMPANY_GROUP                    : "admin/company-group/",
    // EDIT_COMPANY_GROUP_IS_ACTIVE            : "admin/company-group/active-status/",

    GET_EDIT_LABEL_DETAILS_BY_COMPANY_ID : "admin/companies",

    //WAREHOUSE
    ADD_WAREHOUSE            : "admin/warehouses",
    EDIT_WAREHOUSE           : "admin/warehouses/",
    DELETE_WAREHOUSE         : "admin/warehouses/",
    EDIT_WAREHOUSE_IS_ACTIVE : "admin/warehouses/active-status/",
    GET_PAGINATED_WAREHOUSES : "admin/warehouses/list",

    //WAREHOUSE ELEMENTS 
    ADD_ELEMENTS             : "admin/warehouse-elements",
    EDIT_ELEMENTS            : "admin/warehouse-elements/",
    DELETE_ELEMENTS          : "admin/warehouse-elements/",
    GET_ELEMENTS             : "admin/warehouse-elements/",

    //GET COMPANY LIST
    GET_ALL_COMPANIES        : "admin/all-companies/list",

    //GET USER LIST
    GET_ALL_USERS           : "admin/all-users/list/",

    //GET UNIT-TYPES LIST
    GET_ALL_UNIT_TYPES      : "admin/all-unit-types/list/",

    //GET WAREHOUSE LIST
    GET_ALL_WAREHOUSES      : "admin/all-warehouses/list/",

    //GET_ALL_CUSTOMERS
    GET_ALL_CUSTOMERS       : "admin/all-customers/list/",

    //USER 
    ADD_USER                : "admin/users",
    EDIT_USER               : "admin/users/",
    DELETE_USER             : "admin/users/",
    EDIT_USER_IS_ACTIVE     : "admin/users/active-status/",
    GET_PAGINATED_USERS     : "admin/users/list",

    USER_RESET_PASSWORD     : "reset-password",

    //UNIT TYPE 
    ADD_UNIT            : "admin/unit-types",
    EDIT_UNIT           : "admin/unit-types/",
    DELETE_UNIT         : "admin/unit-types/",
    EDIT_UNIT_IS_ACTIVE : "admin/unit-types/active-status/",
    GET_PAGINATED_UNITS : "admin/unit-types/list",

    //UNIT TYPE 
    GET_PAGINATED_WAREHOUSE_UNITS  : "admin/units/list",
    EDIT_WAREHOUSE_UNITS_IS_ACTIVE : "admin/units/active-status/",

    //CUSTOMER 
    ADD_CUSTOMER             : "admin/customers",
    EDIT_CUSTOMER            : "admin/customers/",
    DELETE_CUSTOMER          : "admin/customers/",
    EDIT_CUSTOMER_IS_ACTIVE  : "admin/customers/active-status/",
    GET_PAGINATED_CUSTOMERS  : "admin/customers/list",
    UPLOAD_CUSTOMER_DATA_CSV : "admin/csv/import/customers",

    //SHIPMENT 
    ADD_SHIPMENT             : "admin/shipments",
    EDIT_SHIPMENT            : "admin/shipments/",
    DELETE_SHIPMENT          : "admin/shipments/",
    EDIT_SHIPMENT_IS_ACTIVE  : "admin/shipments/active-status/",
    GET_PAGINATED_SHIPMENTS  : "admin/shipments/list",
    UPLOAD_SHIPMENT_DATA_CSV : "admin/csv/import/shipments",
    GET_SHIPMENT_TYPE_LIST   : "import/mover-inventory/shipment-type-list/",

    //TAGS
    ADD_TAGS            : "admin/tags",
    EDIT_TAGS           : "admin/tags/",
    DELETE_TAGS         : "admin/tags/",
    GET_PAGINATED_TAGS  : "admin/tags/list",
    GET_ALL_TAGS        : "admin/tags/",

    //STATIC PAGES
    GET_PAGES           : "admin/pages/list",
    EDIT_PAGES          : "admin/pages/",
    ADD_FAQ             : "admin/pages/",
    EDIT_FAQ            : "admin/pages/",
    DELETE_FAQ          : "admin/pages/"
}